<template>
  <div class="choose">
    <van-cell-group>
      <template #title>
      <div class="question theme-color">
        请选择账户类型
        <van-icon name="info-o" @click="showAlertMsg"/>
      </div>
      </template>
      <van-radio-group class="check" v-model="form.account_type" :disabled="isAddAccount">
        <van-radio icon-size="24px" name="证券现金账户">证券现金账户</van-radio>
        <van-radio icon-size="24px" name="证券保证金账户">证券保证金账户</van-radio>
      </van-radio-group>
    </van-cell-group>
    <div class="read">
      [{{form.stock_agreement === '是' ? '已' : '未' }}阅读]
      <span class="link" @click="link('choose_stock')">客户协议书</span>
    </div>
    <div class="read">
      [{{form.common_sense === '是' ? '已' : '未' }}阅读]
      <span class="link" @click="link('choose_common')">客户常设授权</span>
    </div>
    <div class="read">
      [{{form.need_know === '是' ? '已' : '未' }}阅读]
      <span class="link" @click="link('choose_need')">沪深港通交易须知及个人资料收集声明</span>
    </div>
    <div class="read">
      [{{form.hkidr_otcr === '是' ? '已' : '未' }}阅读]
      <span class="link" @click="link('hkidr_otcr')">HKIDR and OTCR</span>
    </div>
    <div @click="handleAcceptAbove">
      <van-checkbox
        style="margin-left: 20px;"
        name="是"
        shape="square"
        v-model="form.accept_above"
      ><div style="padding: 10px;">
      本人已经细阅、明白及接受上述文件的条款</div></van-checkbox>
    </div>
  </div>
  <div style="width: 100%;text-align: center;">
    <van-button type="primary" class="btn" @click="next" :disabled="disabledNext">
      下一步 {{isAddAccount ? '' : '(资料填写)'}}
    </van-button>
  </div>
</template>

<script>
import {
  Button, RadioGroup, Radio, CheckboxGroup, Checkbox, CellGroup, Icon, Dialog,
} from 'vant';
import {
  ref,
  watch,
  reactive,
  onMounted,
  computed,
} from 'vue';
import { useStore } from 'vuex';
import httpApi from '../../utils/httpApi';
import httpAddApi from '../../utils/httpAddApi';

let submitForm = null;
const alertMsg = `证券现金账户：只能用自有资金进行交易，不能进行融资以放大杠杆交易的账户。
证券保证金账户：可以使用自有资金，同时可以申请融资以放大杠杆进行交易的账户。`;
export default {
  name: 'Choose',
  components: {
    [Button.name]: Button,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Checkbox.name]: Checkbox,
    [CellGroup.name]: CellGroup,
    [CheckboxGroup.name]: CheckboxGroup,
    [Icon.name]: Icon,
  },
  watch: {
    // eslint-disable-next-line func-names
    'form.account_type': function () {
      if (this.form.account_type !== '证券保证金账户') {
        this.form.account = [];
      }
    },
  },
  mounted() {
    this.refresh();
  },
  data() {
    return {};
  },
  methods: {
    handleAcceptAbove(key) {
      const form = JSON.parse(JSON.stringify(this.form));
      if (key === 'account') {
        if (form.futures_agreement !== '是') {
          this.form.accept_above = false;
        }
      } else if (form.accept_above) {
        if (!form.account_type) {
          this.$toast('请选择账户类型！');
          this.form.accept_above = false;
          return;
        }
        const array = ['common_sense', 'stock_agreement', 'need_know', 'hkidr_otcr'];
        if (form.account.length > 0) {
          array.push('futures_agreement');
        }
        array.forEach((item) => {
          if (this.form[item] !== '是') {
            this.$toast('请先阅读所有文件条款！');
            this.form.accept_above = false;
          }
        });
      }
    },
    async refresh() {
      this.$store.commit('setTitleValue', '选择账户');
      this.$store.commit('setStepsShow', false);
      this.$store.commit('setIsEnterAccount', true);
      const httprequest = this.isAddAccount
        ? httpAddApi.getAccountAgreementStatus : httpApi.getChooseStepOne;
      const result = await httprequest();
      if (!result) return;
      this.form.account = [];
      Object.getOwnPropertyNames(this.form).forEach((item) => {
        if (result.data[item] !== undefined) {
          if (item === 'accept_above') {
            this.form[item] = (result.data[item] === '是');
          } else if (item === 'account_type') {
            const [value1, value2] = result.data[item].split('-');
            this.form[item] = value1;
            if (value2) this.form.account[0] = value2;
          } else {
            this.form[item] = result.data[item];
          }
        }
      });
    },
    showAlertMsg() {
      Dialog.alert({
        title: '说明',
        messageAlign: 'left',
        message: alertMsg,
        confirmButtonText: '我知道了',
        confirmButtonColor: 'rgba(0,0,0,0.6)',
      });
    },

    async link(name) {
      const httprequest = this.isAddAccount
        ? this.$addapi.updateAccountAgreementStatus : this.$api.postChooseStepOne;
      const result = await httprequest(submitForm);
      if (!result) return;
      this.$router.push({ name });
    },

    async next() {
      if (this.isAddAccount) await this.$api.submitLog({ type: 'submitAddAccountAgreemen' });
      const httprequest = this.isAddAccount
        ? this.$addapi.updateAccountAgreementStatus : this.$api.postChooseStepOne;
      const result = await httprequest(submitForm);
      if (!result) return;
      const path = this.isAddAccount ? '/assess/index' : '/info/step_one';
      this.$router.push({ path });
    },
  },
  setup() {
    const form = reactive({
      account: [],
      accept_above: false,
      account_type: '',

      common_sense: '',
      futures_agreement: '',
      need_know: '',
      hkidr_otcr: '',
      stock_agreement: '',
    });
    const disabledNext = ref(true);
    onMounted(async () => {
      const store = useStore();
      store.commit('setTitleValue', '选择账户');
      store.commit('setStepsShow', false);
    });
    watch(form, (value) => {
      submitForm = { ...value };
      if (submitForm.account.length > 0 && submitForm.account_type === '证券保证金账户') {
        submitForm.account_type = `${submitForm.account_type}-${value.account[0]}`;
      }
      submitForm.accept_above = (form.accept_above ? '是' : '否');
      disabledNext.value = false;
      const array = ['stock_agreement', 'common_sense', 'need_know', 'hkidr_otcr'];

      if (submitForm.account.length) array.push('futures_agreement');
      delete submitForm.account;
      array.forEach((item) => {
        if (submitForm[item] !== '是') {
          disabledNext.value = true;
        }
      });
      if (submitForm.accept_above === '否') {
        disabledNext.value = true;
      }
      if (!submitForm.account_type) {
        disabledNext.value = true;
      }
      httpApi.formLog(submitForm, disabledNext);
    });
    const store = useStore();
    const isAddAccount = computed(() => store.state.user.isAddAccount);
    return {
      form,
      disabledNext,
      isAddAccount,
    };
  },
};
</script>

<style lang="less">
.question {
  text-align: left;
  color: font-color-0;
}
.choose {
  .van-radio {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: @space-3;
  }
  .van-checkbox-group {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-left: 50px;
    padding-top: @space-1;
    padding-bottom: @space-2;
  }
  div:first-child {
    border-top: none;
  }
}
.read {
  padding-left: 20px;
  padding-top: @space-4;
  text-align: left;
}
.check {
  .van-checkbox {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: @space-2 0 @space-2 calc(@space-0 + @space-0);
  }
}
.btn {
  width: 80%;
  margin: @space-0 auto;
  margin-bottom: 0;
}
.question {
  .van-icon {
    top: 1px;
  }
}
</style>
